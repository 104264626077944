<template>
  <DashboardContainer>
    <!-- leads-table-area  -->
    <div class="leads-table-area addcustomer-table">
      <table id="leads_table" class="display">
        <thead>
          <tr>
            <th>Agent Name</th>
            <th>Customer Name</th>
            <th>Lead Id</th>
            <th style="width: 200px">Delete Date</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- leads-table-area  -->
  </DashboardContainer>
</template>

<script>
import DashboardContainer from "../containers/DashboardContainer";
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import "../assets/css/dataTables.css";

export default {
  name: "LoginLogs",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  components: {
    DashboardContainer,
  },
  methods: {
    getLoginLogs() {
      $("#leads_table")
        .addClass("nowrap")
        .dataTable({
          order: [[3, "desc"]],
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          searching: false,
          ajax: {
            url: axios.defaults.baseURL + "deleteleadlogs",
            type: "post",
            data: function (response) {
              console.log(response.data);
            },
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          },
          columns: [
            { data: "user_name" },
            { data: "customer_name" },
            { data: "lead_id" },

            { data: "date" },
          ],
          columnDefs: [
            {
              className: "custom_width",
              targets: 1,
            },
          ],
        });
    },
  },
  mounted() {
    this.getLoginLogs();
  },
};
</script>
<style>
#leads_table .custom_width {
  width: 300px !important;
  max-width: 300px !important;
  white-space: pre-wrap !important;
}
</style>